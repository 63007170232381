
import { bool } from 'vue-types';

export default {
  props: {
    close: bool().def(false),
  },
  methods: {
    onMenuButtonClick() {
      this.$store.commit('toggleMenu');
    },
  },
};
