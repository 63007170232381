import { render, staticRenderFns } from "./MenuButton.vue?vue&type=template&id=c3989158&scoped=true"
import script from "./MenuButton.vue?vue&type=script&lang=js"
export * from "./MenuButton.vue?vue&type=script&lang=js"
import style0 from "./MenuButton.vue?vue&type=style&index=0&id=c3989158&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3989158",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconButton: require('/home/runner/work/tomasu-website/tomasu-website/app/components/buttons/IconButton.vue').default})
