import { render, staticRenderFns } from "./Nav.vue?vue&type=template&id=06cc213c&scoped=true"
import script from "./Nav.vue?vue&type=script&lang=js"
export * from "./Nav.vue?vue&type=script&lang=js"
import style0 from "./Nav.vue?vue&type=style&index=0&id=06cc213c&prod&lang=postcss"
import style1 from "./Nav.vue?vue&type=style&index=1&id=06cc213c&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06cc213c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewsBar: require('/home/runner/work/tomasu-website/tomasu-website/app/components/news/NewsBar.vue').default,NavMobile: require('/home/runner/work/tomasu-website/tomasu-website/app/components/nav/NavMobile.vue').default,MenuButton: require('/home/runner/work/tomasu-website/tomasu-website/app/components/buttons/MenuButton.vue').default,Icon: require('/home/runner/work/tomasu-website/tomasu-website/app/components/icons/Icon.vue').default,CartCount: require('/home/runner/work/tomasu-website/tomasu-website/app/components/text/CartCount.vue').default,Nav: require('/home/runner/work/tomasu-website/tomasu-website/app/components/nav/Nav.vue').default})
